import { useState } from "react";
import Carousel from "../../common/Carousel";
import Styles from "./ourcenter.module.css";
import { StarFilled } from "@ant-design/icons";
import ID from "../../../constants/id";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Button, Divider, Flex, Radio ,Row} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Box } from '@mui/material';

const OurCenter = () => {
  const [currentSlide, setCurrentSlide] = useState(1);

  return (
    <div className={Styles.container} id={ID.TESTIMONIALS}>
      <div className={Styles.heading}>Our Centers</div>
      <div className={Styles.carouselContainer}>
        <Box sx={{display:'flex'}}>
		  {centerList.map((list, i) => (
          <div className={`${Styles.card}`}>
            <div className={Styles.cardHeading}>{list.centerName}</div>
            <div className={Styles.userContainer}>
              <LocationOnIcon fontSize="large" />
              <div>
                {Boolean(list.text)&&<div className={Styles.user}>
                  <span className={Styles.titlerole}>{list.text}</span>
                </div>}
              <div className={Styles.institution} onClick={() => { 
                function open(url) {
                  const win = window.open(url, '_blank');
                  if (win != null) {
                    win.focus();
                  }
                }
                open(list.ctalink)
              }}>{Boolean(list.ctalink)?`Open In Maps`:'Opening Soon'}</div>
                {/* <Button type="link" shape="default" size={"large"}>
                  Open in Maps
                </Button> */}
              </div>
            </div>
          </div>
		))}
          {/* <div className={`${Styles.blur} ${Styles.rightBlur}`} /> */}
          </Box>
      </div>
    </div>
  );
};

export default OurCenter;

const centerList = [
  {
    centerName: "Faridabad",
    text: "12/17 Opposite HDFC Bank, Sector 17 Market, Faridabad",
    ctalink: "https://maps.app.goo.gl/4hG2aUyBk6YfLASw9",
  },
  {
    centerName: "Delhi",
    text: "",
    ctalink: "",
  },
  {
    centerName: "Gurgaon",
    text: "",
    ctalink: "",
  },
  {
    centerName: "Noida",
    text: "",
    ctalink: "",
  },
  {
    centerName: "Kolkata",
    text: "",
    ctalink: "",
  },
  {
    centerName: "Bengaluru",
    text: "",
    ctalink: "",
  },
  {
    centerName: "Navi Mumbai",
    text: "",
    ctalink: "",
  },
];
