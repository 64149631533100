import { useState } from "react";
import Carousel from "../../common/Carousel";
import Styles from "./learnlatestech.module.css";
import RoboticsImg from "../../../images/offerings/robotics.png";
import JCodingImg from "../../../images/offerings/junior-coding.png";
import SCodingImg from "../../../images/offerings/senior-coding.png";
import ID from "../../../constants/id";
import { Carousel as AntdCarousel } from "antd";
import useMediaQuery from "../../../hooks/use-media-query";

const LearnLatestTech = () => {
	const isMobile = useMediaQuery(991);
	const [currentSlide, setCurrentSlide] = useState(1);
	const onChange = (_, nextSlide) => {
		setCurrentSlide(nextSlide + 1 >= offerings.length ? 0 : nextSlide + 1);
	};
	return (
		<div className={Styles.container} id={ID.OFFERINGS}>
			<div className={Styles.heading}>Learn Latest Technologies is fun</div>
			<div>
			<AntdCarousel
					slidesToScroll={1}
					autoplay
					autoplaySpeed={2000}
					slidesToShow={isMobile?1:5}
					beforeChange={(...props) => {
						onChange && onChange(...props);
					}}
					dots={true}
					dotHeight={0}
					>
				{offerings.map((offer, i) => (
						<div key={i} className="d-flex justify-center">
							<div className={`${Styles.card} ${currentSlide === i && Styles.active}`}>
								<div className={Styles.imgContainer}>
									<img src={offer.img} alt="offering" width={150} />
								</div>
								<div className={Styles.cardHeading}>{offer.title}</div>
								{/* <div className={Styles.cardText}>{offer.text}</div> */}
							</div>
						</div>
					))}
			</AntdCarousel>	
			</div>
		</div>
	);
};

export default LearnLatestTech;

const offerings = [
	{
		img: RoboticsImg,
		title: "Basic Electronics",
	},
	{
		img: JCodingImg,
		title: "Arduino",
	},
	{
		img: SCodingImg,
		title: "ESP32",
	},
	{
		img: RoboticsImg,
		title: "Python",
	},
	{
		img: JCodingImg,
		title: "Block Based Coding",
	},
	{
		img: SCodingImg,
		title: "App Inventor",
	},
];
