import SolidButton from "../common/SolidButton";
import Styles from "./landing.module.css";
import LandingImg from "../../images/landing1.jpg";
import RoboticsLogo from "../../images/robotics-logo.png";
import CodingLogo from "../../images/coding-logo.png";
import ID from "../../constants/id";
import { HashLink } from "react-router-hash-link";
import video from "../../assets/landingVideo.mp4";
import LeafLetmodal from "../../adModal/LeafLetmodal";
import React, { useState } from "react";

// import { CaretRightFilled } from "@ant-design/icons";

const Landing = () => {
  const [modalOpen, setModalOpen] = useState(true);

  const toggleModal = (e) => {
    e.stopPropagation();
    setModalOpen(!modalOpen);
  };

  return (
    <div className={Styles.container}>
      {/* <LeafLetmodal isOpen={modalOpen} onClose={toggleModal} /> */}
      <div className="overlay">{/* <div class="overlay-content"></div> */}</div>
      <div className="d-flex justify-between">
        <div className={Styles.leftContainer}>
          <div className={Styles.heading}>Mastering Machines</div>
          <div className={Styles.subHeading}>
            RoboArmy provides the best hands-on robotics courses to your kids{" "}
            <br />
            <b>(Class V to IX)</b> to make them future ready
          </div>
          <div className={Styles.streamContainer}>
            <div className={Styles.stream}>
              <img src={RoboticsLogo} alt="robotics" />
              Robotics
            </div>
            <div className={Styles.stream}>
              <img src={CodingLogo} alt="coding" />
              Coding
            </div>
          </div>
          <div className={Styles.ctaContainer}>
            <HashLink to={`#${ID.REQUEST}`}>
              <SolidButton>Know more</SolidButton>
            </HashLink>
            {/* <div className={Styles.explore}>Explore workshops</div>
						<div className={Styles.exploreBtn}>
							<OutlinedButton style={{ marginLeft: 56 }}>
								<CaretRightFilled style={{ marginRight: 12, fontSize: 19 }} />
								Explore workshops
							</OutlinedButton>
						</div> */}
          </div>
        </div>
        <div className={Styles.rightContainer}>
          <img src={LandingImg} alt="landing_img" />
        </div>
      </div>
      {/* <video autoPlay muted loop className={Styles.banner_video}>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
      </video> */}
      {/* <div className={Styles.highlightContainer}>
        {highlights.map((highlight, i) => (
          <div key={i} className={Styles.highlight}>
            <div className={Styles.highlightLabel}>{highlight.label}</div>
            <div className={Styles.highlightValue}>{highlight.value}</div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Landing;

const highlights = [
  {
    label: "Associated schools",
    value: "30+",
  },
  {
    label: "Students",
    value: "11000+",
  },
  {
    label: "Workshops conducted",
    value: "4k+",
  },
  {
    label: "Robotics labs",
    value: "30+",
  },
];
