import { useState } from "react";
import Styles from "./learnonline.module.css";
import RoboticsImg from "../../../images/offerings/robotics.png";
import JCodingImg from "../../../images/offerings/junior-coding.png";
import SCodingImg from "../../../images/offerings/senior-coding.png";
import ID from "../../../constants/id";
import useMediaQuery from "../../../hooks/use-media-query";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import {AmazonOutlined  } from '@ant-design/icons'

const LearnOnline = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(991);
  const [currentSlide, setCurrentSlide] = useState(1);

  return (
    <div className={Styles.container} id={ID.OFFERINGS}>
      <div className={Styles.heading}>Learn Robotics through Live Classes</div>
      <Card style={{ padding: isMobile?"20px":"80px" }}>
        <div style={{ display: "flex" }}>
          <AmazonOutlined
            style={{ fontSize: isMobile?'70px':'111px', padding: isMobile?'15px':'20px' }} /> 
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <CardContent>
              <Typography component="div" variant={isMobile?"h6":"h5"}>
                Order on Amazon & receive DIY kits at doorstep
              </Typography>
            </CardContent>
          </Box>
        </div>

        <div style={{ display: "flex" }}>
          <CardMedia
            component="img"
            sx={{ width: isMobile?'100px':'151px' ,height:isMobile?'100px':'151px'}}
            image={RoboticsImg}
            alt="Live from space album cover"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <CardContent>
              <Typography component="div" variant={isMobile?"h6":"h5"}>
                Learn by Building cool robotics projects
              </Typography>
            </CardContent>
          </Box>
        </div>

        <div style={{ display: "flex" }}>
          <CardMedia
            component="img"
            sx={{ width: isMobile?'100px':'151px' ,height:isMobile?'100px':'151px'}}
            image={SCodingImg}
            alt="Live from space album cover"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <CardContent>
              <Typography component="div" variant={isMobile?"h6":"h5"}>
                Live classes from expert teachers
              </Typography>
            </CardContent>
          </Box>
        </div>
      </Card>
    </div>
  );
};

export default LearnOnline;
