import * as React from "react";

const GearIcon = ({ color, classname, width, height }) => {
  return (
    <svg
      className={classname}
      fill={`${color}`}
      viewBox="4.483 6.667 120.882 120.881"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="path83"
        // style="stroke: rgb(222, 222, 221); stroke-width: 0.057358; fill: rgb(69, 69, 93);"
        d="M 78.477 6.667 L 70.835 20.04 C 68.885 19.802 66.895 19.682 64.865 19.682 C 62.596 19.682 60.327 19.802 58.138 20.16 L 49.7 7.065 C 45.361 8.219 41.222 9.811 37.321 11.841 L 39.988 27.006 C 36.405 29.314 33.181 32.14 30.355 35.325 L 15.549 30.827 C 13.001 34.449 10.812 38.31 9.101 42.449 L 20.763 52.36 C 19.529 56.341 18.852 60.6 18.733 64.978 L 4.483 71.187 C 4.842 75.685 5.677 80.103 6.951 84.282 L 22.196 84.243 C 23.867 88.263 26.057 91.964 28.644 95.308 L 21.718 109.279 C 24.823 112.423 28.206 115.249 31.947 117.677 L 43.61 107.806 C 47.351 109.756 51.332 111.189 55.551 112.065 L 59.133 127.27 C 61.004 127.429 62.914 127.548 64.865 127.548 C 67.372 127.548 69.88 127.389 72.308 127.071 L 74.975 111.866 C 79.154 110.95 83.134 109.438 86.796 107.408 L 99.215 116.762 C 102.837 114.214 106.22 111.309 109.205 108.045 L 101.563 94.671 C 104.11 91.288 106.26 87.546 107.852 83.526 L 123.256 82.65 C 124.45 78.431 125.126 74.013 125.365 69.476 L 111.036 64.182 C 110.837 59.804 110.041 55.624 108.727 51.644 L 119.992 40.937 C 118.161 36.837 115.852 32.976 113.225 29.434 L 98.856 34.728 C 95.99 31.583 92.727 28.877 89.105 26.608 L 90.936 11.085 C 86.995 9.174 82.816 7.662 78.477 6.667 Z"
        transform="matrix(1, 0, 0, 1, 0, 7.105427357601002e-15)"
      />
    </svg>
  );
};

export default GearIcon;
