import Styles from "./project.module.css";
import useMediaQuery from "../../hooks/use-media-query";
import ID from "../../constants/id";
import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Layout from "../Layout";
import Header from "../Layout/header";
import Axios from "axios";
import { Space, Table, Tag, Button } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import parse from "html-react-parser";

const Projetcs = () => {
  const isMobile = useMediaQuery(991);
  const params = useParams();

  const navigate = useNavigate();
  const [data, setdata] = useState();
  const [isLoading, setIsLoading] = useState(true);

  async function a() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://ap.roboarmy.in/api/project/",
      headers: {},
    };

    Axios.request(config)
      .then((response) => {
        setdata(response.data.payload);
        setIsLoading(false);
        console.log(JSON.stringify(response.data.payload));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    a();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }
  return (
    <div>
      <Header />
      <div className={Styles.container}>
        {data.map((item, index) => {
          return (
            <div
              className={Styles.card}
              style={{ display: isMobile ? "block" : "" }}
            >
              <img
                src={`https://roboarmythumbnail.s3.ap-south-1.amazonaws.com/project/${item.id}.png`}
                alt="Logo"
                style={{
                  width: isMobile ? 350 : 300,
                  height: 200,
                  borderRadius: 16,
                }}
              />
              <div className={Styles.cardHeading}>
                {`${item.project_name}`}
                <p style={{ fontWeight: "normal", paddingTop: 20 }}>
                  {parse(item.description)}
                </p>
                <Button
                  type="primary"
                  shape="round"
                  icon={<PlayCircleOutlined />}
                  size={"large"}
                  onClick={() => {
                    navigate(`/project/${item.id}`);
                  }}
                >
                  Watch now
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Projetcs;
