import { useState } from "react";
import Styles from "./learningApprorach.module.css";
import ID from "../../../constants/id";
import useMediaQuery from "../../../hooks/use-media-query";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import GearIcon from "../../../images/gear";
import {BulbOutlined,DeploymentUnitOutlined,FileProtectOutlined  } from '@ant-design/icons';
import { BubbleChart } from "@mui/icons-material";

const LearningApproach = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(991);
  const [currentSlide, setCurrentSlide] = useState(1);

  return (
    <div className={Styles.container} id={ID.OFFERINGS}>
          <div className={Styles.heading}>Learning Approach</div>
          <div style={{justifyContent:'center',alignContent:'center',alignItems:'center'}}>
              <div className={Styles.card}>
                  <div style={{display:'flex',justifyContent:'center'}}>
                  <GearIcon color={'#61d800'} height={isMobile?'50px':'100px'} width={isMobile?'50px':'100px'} classname={Styles.gearStyle}/>
                      <BulbOutlined className={Styles.overSvg} />
                    </div>
              <div className={Styles.attachTitle}><h3>Ideate on Real Life Problem</h3></div>
          </div>
              <div className={Styles.card}>
              <div style={{display:'flex',justifyContent:'center'}}>
                  <GearIcon color={'#BA68C8'} height={isMobile?'50px':'100px'} width={isMobile?'50px':'100px'} classname={Styles.gearStyle}/>
                      <DeploymentUnitOutlined className={ Styles.overSvg} style={{marginTop:isMobile?'14px':'20px'}} />
                    </div>
              <div className={Styles.attachTitle}><h3>Learn latest technology to solve the problem</h3></div>
          </div>
              <div className={Styles.card}>
              <div style={{display:'flex',justifyContent:'center'}}>
                  <GearIcon color={'#90CAF9'} height={isMobile?'50px':'100px'} width={isMobile?'50px':'100px'} classname={Styles.gearStyle}/>
                      <FileProtectOutlined className={ Styles.overSvg} />
                    </div>
              <div className={Styles.attachTitle}><h3>Create solution as a Project</h3></div>
          </div>
        </div>
    </div>
  );
};

export default LearningApproach;
