import { Drawer } from "antd";
import RoboLogo from "../../images/robocode.svg";
import Styles from "./layout.module.css";
import { CloseOutlined } from "@ant-design/icons";
import SolidButton from "../common/SolidButton";
import { HashLink } from "react-router-hash-link";

const Sidedrawer = ({ openSidebar, onClose, LINKS, setOpenLogin }) => {
	return (
		<Drawer
			bodyStyle={{
				background: "#FAFAFA",
			}}
			placement="left"
			onClose={onClose}
			open={openSidebar}
			closable={false}
			width="80%">
			<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
				<img src={RoboLogo} alt="logo" className={Styles.sidebarRoboLogo} />
				<CloseOutlined className={Styles.closeIcon} onClick={onClose} />
				<div className={Styles.sidebarDivider} />
				{LINKS.map((link, i) => (
					<HashLink 
						onClick={() => {
							if (link.href === "/" || link.href === "/#") {
								window.scrollTo(0, 0);
							}
							onClose();
						}} 
						className={Styles.sidebarLink} 
						key={i} 
						to={link.href} 
						scroll={!!link.scroll}
						smooth
					>
						{link.label}
					</HashLink>
				))}
				<div style={{ flexGrow: 1 }} />
				{/* <SolidButton
					onClick={() => {
						onClose();
						setOpenLogin(true);
					}}
					small
					style={{ width: "100%" }}>
					Login
				</SolidButton> */}
			</div>
		</Drawer>
	);
};

export default Sidedrawer;
