import React from "react";
import styles from "./solid-button.module.css";

const SolidButton = ({ onClick = () => {}, disabled = false, children, small, style = {} }) => {
	return (
		<button
			className={`${styles.button} ${small && styles.smallButton} `}
			disabled={disabled}
			onClick={() => {
				onClick();
			}}
			style={style}>
			{children}
		</button>
	);
};

export default SolidButton;
