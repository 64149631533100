import Styles from "./carousel.module.css";
import { Carousel as AntdCarousel } from "antd";
import { useRef } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import useMediaQuery from "../../../hooks/use-media-query";

const Carousel = ({ children, onChange, enforceThree }) => {
	const ref = useRef();
	const mobRef = useRef();
	const isMobile = useMediaQuery(991);

	return (
		<div style={{ position: "relative" }}>
			<AntdCarousel
				ref={isMobile ? mobRef : ref}
				beforeChange={(...props) => {
					onChange && onChange(...props);
				}}
				slidesToShow={enforceThree ? 3 : isMobile ? 1 : 3}
				slidesToScroll={1}
				dots={false}>
				{children}
			</AntdCarousel>
			<div className={Styles.carouselBtnContainer}>
				<div className={Styles.arrow} onClick={() => (isMobile ? mobRef : ref).current.prev()}>
					<ArrowLeftOutlined style={{ color: "#313131" }} />
				</div>
				<div className={`${Styles.arrow} ${Styles.rightArrow}`} onClick={() => (isMobile ? mobRef : ref).current.next()}>
					<ArrowRightOutlined style={{ color: "#ffffff" }} />
				</div>
			</div>
		</div>
	);
};

export default Carousel;
