import AboutUs from "../Landing/AboutUs";
import Landing from "../Landing";
import Layout from "../Layout";
import Offerings from "../Landing/Offerings";
import Gallery from "../Landing/Gallery";
import Feedbacks from "../Landing/Feedbacks";
import Register from "../Landing/Register";
import RequestDemo from "../Landing/RequestDemo";
import useMediaQuery from "../../hooks/use-media-query";
import { useEffect } from "react";
import PopularCourses from "../Landing/PopularCourses";
import OurCenter from "../Landing/OurCenters";
import LearnLatestTech from "../Landing/LearnLatestTech";
import LearnOnline from "../Landing/LearnOnline";
import LearningApproach from "../Landing/LearningApprorach";

let flag = false;

const Home = () => {
  const isBreakpoint = useMediaQuery(991);

  useEffect(() => {
    if (window.location.hash) {
      let hash = window.location.hash.replaceAll("#", "");
      let element = document.getElementById(hash);

      if (element && !flag) {
        flag = true;
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 50);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  return (
    <Layout>
      {isBreakpoint ? (
        <>
          <Landing />
          <PopularCourses />
          <LearningApproach/>
          <OurCenter />
				  <LearnLatestTech />
				  <LearnOnline/>
          {/* <Offerings /> */}
          <Gallery />
          <AboutUs />
          <Register />
          {/* <RequestDemo /> */}
          <Feedbacks />
        </>
      ) : (
        <>
          <Landing />
          <PopularCourses />
          <LearningApproach/>
          <OurCenter />
					<LearnLatestTech />
					<LearnOnline/>
          {/* <Offerings /> */}
          <Gallery />
          <AboutUs />
          <Register />
          <Feedbacks />
          {/* <RequestDemo /> */}
        </>
      )}
    </Layout>
  );
};

export default Home;
