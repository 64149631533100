import { useRef } from "react";
import Styles from "./horizontal-scroll.module.css";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

const HorizontalScroll = ({ children }) => {
	const scrollRef = useRef();

	const scrollLeft = () => {
		const scroller = scrollRef.current;
		if (scroller.scrollLeft === 0) scroller.scrollLeft = scroller.scrollWidth;
		else scroller.scrollLeft -= scroller.offsetWidth;
	};

	const scrollRight = () => {
		const scroller = scrollRef.current;
		if (scroller.scrollLeft + scroller.offsetWidth + 1 >= scroller.scrollWidth) scroller.scrollLeft = 0;
		else scroller.scrollLeft += scroller.offsetWidth;
	};

	return (
		<div style={{ position: "relative" }}>
			<div className={Styles.scrollerWrapper} ref={scrollRef}>
				{children}
			</div>
			<div className={Styles.arrowWrapper}>
				<div className={Styles.arrow} onClick={scrollLeft}>
					<ArrowLeftOutlined style={{ color: "#313131" }} />
				</div>
				<div className={`${Styles.arrow} ${Styles.rightArrow}`} onClick={scrollRight}>
					<ArrowRightOutlined style={{ color: "#ffffff" }} />
				</div>
			</div>
		</div>
	);
};

export default HorizontalScroll;
