import Styles from "./layout.module.css";
import RoboLogo from "../../images/robocode.svg";
import HamburgerIcon from "../../images/hamburger.png";
import { useNavigate } from "react-router-dom";
import SolidButton from "../common/SolidButton";
import { useState } from "react";
import Sidedrawer from "./sidedrawer";
import ID from "../../constants/id";
import SignInModal from "./SignInModal";
import { HashLink } from "react-router-hash-link";
import { WhatsAppOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Header = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <nav className={Styles.navbar}>
        <img
          src={HamburgerIcon}
          onClick={() => {
            setOpenSidebar(true);
          }}
          alt="hamburger"
          className={Styles.hamburger}
        />
        <div className="d-flex align-items-center">
          <img
            src={RoboLogo}
            alt="logo"
            className={Styles.headerRoboLogo}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/");
            }}
          />
        </div>
        <div className={Styles.navLinks}>
          {LINKS.map((link, i) => (
            <HashLink
              to={link.href}
              smooth
              onClick={() => {
                if (link.href === "/" || link.href === "/#") {
                  window.scrollTo(0, 0);
                }
              }}
            >
              {link.label}
            </HashLink>
          ))}
          {/* <SolidButton
						onClick={() => {
							setOpenLogin(true);
						}}
						small
						style={{ marginLeft: "1.25vw" }}>
						Login
					</SolidButton> */}
        </div>
        {/* <div>
          <WhatsAppOutlined
            onClick={() => {
              window.open(`https://wa.me/918888103688/?text=*Welcome to www.ROBOARMY.in* \n\n
							We're thrilled to have you here! Feel free to share ideas, ask questions, and connect with us.`);
            }}
            className={Styles.whatsApp}
          />
        </div> */}
      </nav>
      <Sidedrawer
        openSidebar={openSidebar}
        onClose={() => {
          setOpenSidebar(false);
        }}
        setOpenLogin={setOpenLogin}
        LINKS={LINKS}
      />
      <SignInModal
        open={openLogin}
        onClose={() => {
          setOpenLogin(false);
        }}
      />
    </>
  );
};

export default Header;

const LINKS = [
  {
    label: "Home",
    href: "/#",
    scroll: true,
  },
  {
    label: "About us",
    href: `/#${ID.ABOUT}`,
  },
  //   {
  //     label: "Offerings",
  //     href: `/#${ID.OFFERINGS}`,
  //   },
  {
    label: "Gallery",
    href: `/#${ID.GALLERY}`,
  },
  {
    label: "Parents Speak",
    href: `/#${ID.TESTIMONIALS}`,
  },
  {
    label: "Projects",
    href: `/project/`,
  },
];
