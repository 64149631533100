import Styles from "./register.module.css";
import { Input, Select } from "antd";
import SolidButton from "../../common/SolidButton";
import ID from "../../../constants/id";
import { useState } from "react";
import swal from "sweetalert";
import { db, analytics } from "../../../firebase.js";
import { collection, addDoc, Timestamp } from "firebase/firestore";

const Register = () => {
  const [data, setData] = useState({
    name: "",
    class: "",
    email: "",
    mobile: "",
    city: "",
    interested_in: null,
  });

  const [error, setError] = useState({
    name: false,
    class: false,
    email: false,
    mobile: false,
    city: false,
    interested_in: false,
  });

  const handleInputData = (e) => {
    setError({ ...error, [e.target.name]: false });
    if (e.target.name === "mobile" && isNaN(e.target.value)) {
      return;
    }
    if (e.target.value.length > limits[e.target.name].max) return;

    setData({ ...data, [e.target.name]: e.target.value });
  };

  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    const tempError = {};

    Object.entries(limits).forEach(([key, limit]) => {
      if (limit.min && data[key].trim().length < limit.min)
        tempError[key] = true;
    });

    if (!data.interested_in) tempError.interested_in = true;
    if (!data.city) tempError.city = true;

    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!data.email.match(validRegex)) {
      tempError.email = true;
    }

    setError(tempError);

    if (Object.keys(tempError).length > 0) return;

    if (loading) {
      return;
    }

    setLoading(true);

    // fetch(`https://script.google.com/macros/s/AKfycbxJYTHYJ6qoe5uS8ztSY1TKx6YLiQMo_PMZLUlL37s/dev`, {
    // 	method: "POST",
    // 	headers: {
    // 		"content-type": "Application/json",
    // 		'Access-Control-Allow-Origin': 'http://localhost:3000',
    // 		'Access-Control-Allow-Credentials':'true'
    // 	},
    // 	mode: 'no-cors',
    // 	body: JSON.stringify(data),
    // })
    // 	.then((res) => res.json())
    // 	.then((res) => {
    // 		if (res?.success) {
    // 			swal("Success!", res?.message, "success");
    // 		} else {
    // 			swal("Error!", res?.message || "Internal server error", "error");
    // 		}
    // 		setData({
    // 			name: "",
    // 			schoolName: "",
    // 			email: "",
    // 			mobile: "",
    // 			studentCount: null,
    // 		});
    // 		setLoading(false);
    // 	});

    handleSubmit(data);
  };

  const handleSubmit = async (data) => {
    // e.preventDefault()
    try {
      await addDoc(collection(db, "userList"), {
        name: data.name,
        class: data.class,
        email: data.email,
        city: data.city,
        mobile:data.mobile,
        interested_in: data.interested_in,
        created: Timestamp.now(),
      }).then(() => {
        swal("Success!",'We will get back to you shortly', "success");
        setData({
          name: "",
          class: "",
          email: "",
          mobile: "",
          city:"",
          interested_in: null,
        });
        setLoading(false);
      });
    } catch (err) {
      swal("Error!" || "Internal server error", "error");
      alert(err);
    }
  };

  return (
    <>
      <div className={Styles.container} id={ID.REQUEST}>
        <div className={Styles.leftContainer}>
          <div className={Styles.heading}>
            Register now for a free demo
            <div className={Styles.subheading}>Welcome to the forefront of innovation where robotics meets real-world applications. At ROBOARMY, we are revolutionizing industries through cutting-edge robotic solutions designed to enhance efficiency, accuracy, and overall performance.</div>
          </div>
        </div>
        <div className={Styles.rightContainer}>
          <div className={Styles.formContainer}>
            <div className={Styles.formHeading}>Lets connect</div>
            <Input
              value={data.name}
              name="name"
              onChange={handleInputData}
              size="large"
              placeholder="Contact name"
            />
            {error.name && (
              <p className={Styles.errorText}>
                {data.name.trim() === ""
                  ? "Enter valid conatct name"
                  : `Minimum ${limits.name.min} characters required`}
              </p>
            )}
            <Input
              value={data.class}
              name="class"
              onChange={handleInputData}
              size="large"
              placeholder="Class"
            />
            {error.class && (
              <p className={Styles.errorText}>
                {data.class.trim() === ""
                  ? "Enter valid class"
                  : `Minimum ${limits.class.min} characters required`}
              </p>
            )}
             <Input
              value={data.city}
              name="city"
              onChange={handleInputData}
              size="large"
              placeholder="City"
            />
            {error.city && (
              <p className={Styles.errorText}>
                {data.city.trim() === ""
                  ? "Enter city"
                  : `Minimum ${limits.city.min} characters required`}
              </p>
            )}
           
            <Input
              value={data.email}
              type="email"
              name="email"
              onChange={handleInputData}
              size="large"
              placeholder="Email ID"
            />
            {error.email && (
              <p className={Styles.errorText}>Enter valid email id</p>
            )}
            <Input
              value={data.mobile}
              name="mobile"
              type="tel"
              onChange={handleInputData}
              size="large"
              placeholder="Phone number"
            />
            {error.mobile && (
              <p className={Styles.errorText}>Enter valid phone number</p>
            )}
            <Select
              options={countOptions}
              value={data.interest}
              onChange={(e) => {
                setData({ ...data, interested_in: e });
                setError({ ...error, interested_in: false });
              }}
              size="large"
              placeholder="Interested-IN"
            />
            {error.interested_in && (
              <p className={Styles.errorText}>Select interested-in</p>
            )}
            <div style={{ textAlign: "center" }}>
              <SolidButton onClick={onSubmit}>Know more</SolidButton>
            </div>
          </div>
        </div>
      </div>
      <style jsx global>{`
        .ant-input-lg {
          background: #f5f8f9;
          border-radius: 12px;
          padding: 16px;
          border: none;
          margin-bottom: 14px;
        }
        .ant-input {
          font-size: 14px !important;
          line-height: 18px !important;
          font-family: "Montserrat";
          font-style: normal;
        }
        .ant-select-selection-placeholder {
          font-size: 14px !important;
          line-height: 18px !important;
        }
        .ant-select-lg {
          width: 100%;
          margin-bottom: 20px;
        }
        .ant-select-selector {
          background: #f5f8f9 !important;
          border-radius: 12px !important;
          height: 50px !important;
          border: none !important;
          display: flex;
          align-items: center;
          padding: 16px !important;
        }
        .ant-select-selection-item {
          font-size: 14px;
        }
      `}</style>
    </>
  );
};

export default Register;

const countOptions = [
  {
    value: "robotics",
    label: "Robotics",
  },
  {
    value: "block-based coding",
    label: "Block-Based Coding",
  },
  {
    value: "Android App Development",
    label: "Android App Development",
  },
  {
    value: "Python",
    label: "Python",
  },
  {
    value: "IoT",
    label: "IoT (Internet of Things)",
  },
  {
    value: "AI",
    label: "AI (Artificial intelligence)",
  },
  {
    value: "Others",
    label: "Others",
  },
];

const limits = {
  name: {
    min: 3,
    max: 50,
  },
  class: {
    min: 1,
    max: 50,
  },
  city: {
    min: 1,
    max: 50,
  },
  email: {
    min: 5,
    max: 255,
  },
  mobile: {
    min: 10,
    max: 10,
  },
};
