import Styles from "./layout.module.css";
import RoboLogo from "../../images/robocode.svg";
import Socials from "../../images/socials";
import { NavLink } from "react-router-dom";
import ID from "../../constants/id";
import useMediaQuery from "../../hooks/use-media-query";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";

const Footer = () => {
  const isMobile = useMediaQuery(991);

  function WhatappButton() {
    return (
      <Helmet>
        <script
          type="text/javascript"
          src="https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js"
          id="aisensy-wa-widget"
          widget-id="g9vsj8"
        ></script>
      </Helmet>
    );
  }
  return (
    <div>
      <WhatappButton />
      <div className={Styles.footerContainer}>
        <div className={Styles.about}>
          <div className={Styles.footerLogoContainer}>
            <img src={RoboLogo} alt="logo" className={Styles.footerRoboLogo} />
            {/* <Image src={ClassLogo} className={Styles.footerClassLogo} /> */}
          </div>
          <div
            className={Styles.footerText}
            style={{
              textAlign: "center",
            }}
          >
            Power your schools to build future for your students
          </div>
        </div>
        <div className="hideDesktop">
          <div className={Styles.footerHeading}>Lets connect</div>
          {/* <Available /> */}
          <div className={Styles.availabeIn}>
            <div>roboarmy404@gmail.com</div>
            {/* <b>Available in:</b>
						<div>Vadodara | Navsari | Navi Mumbai | Pune | New Delhi | Chennai | Gurgaon</div> */}
          </div>
        </div>
        <div className={Styles.resources}>
          <div className={Styles.footerHeading}>Company</div>
          <HashLink to={`#${ID.ABOUT}`} className={Styles.footerText}>
            About us
          </HashLink>
          <NavLink
            to="/terms-and-conditions/"
            target="_blank"
            className={Styles.footerText}
          >
            Terms of use
          </NavLink>
          <NavLink
            to="/privacy-policy/"
            target="_blank"
            className={Styles.footerText}
          >
            Privacy
          </NavLink>
        </div>
        <div className={Styles.company}>
          <div className={Styles.footerHeading}>Resources</div>
          <NavLink
            to="https://scratch.mit.edu/"
            target="_blank"
            className={Styles.footerText}
          >
            Scratch
          </NavLink>
          <NavLink
            to="https://code.org/"
            target="_blank"
            className={Styles.footerText}
          >
            Code.org
          </NavLink>
        </div>
        <div className={Styles.subscribe}>
          <div className="hideMobile">
            <div className={Styles.footerHeading}>Lets connect</div>
            {/* <Available /> */}
            <div className={Styles.availabeIn}>
              <div>roboarmy404@gmail.com</div>
              {/* <b>Available in:</b>
						<div>Vadodara | Navsari | Navi Mumbai | Pune | New Delhi | Chennai | Gurgaon</div> */}
            </div>
          </div>
        </div>
        {/* <div className="hideMobile" style={{ width: "100%" }}>
					<div className={Styles.availabeIn}>
						<b>Available in:</b>
						<div>&emsp;Vadodara | Navsari | Navi Mumbai | Pune | New Delhi | Chennai | Gurgaon</div>
					</div>
				</div> */}
        <div className={Styles.footerDivider} />
        <div className={Styles.copyright}>
          Copyright {new Date().getFullYear()} ©RoboArmy{" "}
          {isMobile ? <br /> : "||"} All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;

const Available = () => {
  return (
    <div>
      <div className="d-flex align-items-center" style={{ marginTop: 24 }}>
        {Object.values(Socials).map((k, i) => (
          <NavLink to={k.link} target="_blank" key={i}>
            {
              <img
                src={k.img}
                alt="social"
                width={28}
                height={28}
                style={{ marginRight: 14 }}
              />
            }
          </NavLink>
        ))}
      </div>
    </div>
  );
};
