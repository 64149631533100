import Styles from "./about-us.module.css";
import AboutImg from "../../../images/about-us.png";
import AboutMobileImg from "../../../images/about-us-mobile.png";
import ID from "../../../constants/id";
import useMediaQuery from "../../../hooks/use-media-query";

const AboutUs = () => {
	const isMobile = useMediaQuery(991);

	return (
		<div className={Styles.container} id={ID.ABOUT}>
			<div className={Styles.leftContainer}>
				<div className={Styles.heading}>About us</div>
				<div className={Styles.text}>
					We at RoboArmy are a group of passionate individuals from IITs and IIMs with a
					vision to transform STEM learning in schools through technology. RoboArmy is a one-stop solution to enable
					schools to design and deliver foundation in coding and robotics to students seamlessly.
				</div>
				{/* <div className={Styles.readMore}>
					Read more
					{!isMobile && <RightCircleOutlined style={{ marginLeft: 14 }} />}
				</div> */}
			</div>
			<div className={Styles.rightContainer}>
				<img src={isMobile ? AboutMobileImg : AboutImg} alt="about" />
			</div>
		</div>
	);
};

export default AboutUs;
