import Footer from "./footer";
import Header from "./header";
import Styles from "./layout.module.css";

const Layout = ({ children }) => {
	return (
		<div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
			<Header />
			<div className={Styles.children}>{children}</div>
			<Footer />
		</div>
	);
};

export default Layout;
