import { Modal } from "antd";
import Styles from "./layout.module.css";
import StudentImg from "../../images/login/student-login.png";
import TeacherImg from "../../images/login/teacher-login.png";
import useMediaQuery from "../../hooks/use-media-query";
import { CloseOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom"

const SignInModal = ({ open, onClose }) => {
	const isMobile = useMediaQuery(991);

	return (
		<>
			<Modal closable={false} width="fit-content" open={open} onCancel={onClose} footer={null}>
				<div className={Styles.modalBody}>
					<div className={Styles.teacherContainer}>
						<img src={TeacherImg} alt="teacher" width={isMobile ? 110 : 256} />
						<div>
							Are you a<br />
							School/Teacher?
						</div>
						<NavLink to="https://app.robocode.live/auth/teacher/login">
							<span
								className={Styles.loginButtonTeacher}
							>
								Login as teacher
							</span>
						</NavLink>
					</div>
					<div className={Styles.studentContainer}>
						<img src={StudentImg} alt="student" width={isMobile ? 110 : 256} />
						<div style={{ marginTop: -32 }}>
							Or are you a<br />
							Student?
						</div>
						<NavLink to="https://app.robocode.live/auth/student/login">
							<span className={Styles.loginButtonStudent}>Login as student</span>
						</NavLink>
					</div>
					<CloseOutlined className={Styles.modalCloseIcon} onClick={onClose} />
				</div>
			</Modal>
			<style jsx="true" global="true">{`
				.ant-modal-content {
					padding: 0px !important;
					border-radius: 40px !important;
					overflow: hidden;
				}
				@media screen and (max-width: 991px) {
					.ant-modal-content {
						border-radius: 8px !important;
					}
				}
			`}</style>
		</>
	);
};

export default SignInModal;
