import Styles from "./projectpage.module.css";
import useMediaQuery from "../../../hooks/use-media-query";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../Layout/header";
import Axios from "axios";
import { Alert } from "antd";
import parse from "html-react-parser";

const ProjetcPage = () => {
  const isMobile = useMediaQuery(991);
  const params = useParams();
  const [data, setdata] = useState();
  const [isLoading, setIsLoading] = useState(true);

  async function a() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://ap.roboarmy.in/api/project-detail/?project_id=${params.id}`,
      headers: {},
    };

    Axios.request(config)
      .then((response) => {
        setdata(response.data.payload);
        // closeTab(response.data.payload.actual_video_url)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // const closeTab = (actual_video) => {
  //   window.opener = null;
  //   window.open(actual_video, "_self");
  //   window.close();
  // };

  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  useEffect(() => {
    a();
  }, []);

  if (isLoading) {
    return (
      <div>
        Please wait..
        {/* <Header /> */}
      </div>
    );
  }
  return (
    <div>
      <Header />
      <div className={Styles.container}>
        <div style={{ width: isMobile ? "100%" : "80%", height: "75%" }}>
          <div style={{ fontWeight: "bold", fontSize: 20 }}>
            {data.project_name}
          </div>
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${youtube_parser(
              data.actual_video_url
            )}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        <div style={{ marginTop: 60 }}>{parse(data.circuit_diagram)}</div>
      </div>
    </div>
  );
};

export default ProjetcPage;
