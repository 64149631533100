import { PlayCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import HorizontalScroll from "../../common/HorizontalScroll";
// import Tag from "../../common/Tag";
import Styles from "./gallery.module.css";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import ID from "../../../constants/id";
import Carousel from "../../common/Carousel";
import useMediaQuery from "../../../hooks/use-media-query";

import Drone from "../../../images/drone.jpg";
import LineFollower from "../../../images/line_follower.jpg";
import DustBin from "../../../images/dust_bin.jpg";
import Class1 from "../../../images/class1.jpg";
import Class2 from "../../../images/class2.jpg";

const Gallery = () => {
	const [activeTag] = useState("All");
	const [photoIndex, setPhotoIndex] = useState(0);
	const [isOpen] = useState(false);
	const [key, setKey] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentSlide, setCurrentSlide] = useState(1);
	const isMobile = useMediaQuery(991);

	// const tags = ["All", ...new Set(gallery.map((obj) => obj.tag).filter((tag) => !!tag))];
	const images = (activeTag === "All" ? gallery : gallery.filter((obj) => obj.tag === activeTag)).filter(
		(obj) => obj.type === "photo"
	);
	const video = gallery.find((obj) => obj.type === "video");

	const onChange = (_, nextSlide) => {
		setCurrentSlide(nextSlide + 1 >= images.length ? 0 : nextSlide + 1);
	};

	useEffect(() => {
		setTimeout(() => setKey(key + 1));
		// eslint-disable-next-line react-hooks/exhaustive-deps  
	}, [isOpen]);

	return (
		<div id={ID.GALLERY} className={Styles.container}>
			<div className={Styles.heading}>Gallery</div>
			<div className={Styles.subHeading}>Our students’ work</div>
			{/* <div className={Styles.tagsContainer}>
				{tags.map((tag, i) => (
					<Tag onClick={() => setActiveTag(tag)} active={tag === activeTag} label={tag} key={i} />
				))}
			</div> */}
			{isMobile ? (
				<div style={{ position: "relative" }}>
					<div className={Styles.blur} />
					<Carousel onChange={onChange} enforceThree>
						{images.map((image, i) => (
							<div key={i} className="d-flex justify-center align-items-center">
								<img
									onClick={() => {
										setPhotoIndex(video ? i + 1 : i);
										setIsModalOpen(true);
									}}
									alt="carousel"
									src={image.url}
									width={currentSlide === i ? "100%" : "85%"}
									style={{ objectFit: "cover", objectPosition: "top", borderRadius: "8px", aspectRatio: "2/3" }}
								/>
							</div>
						))}
					</Carousel>
					<div className={`${Styles.blur} ${Styles.rightBlur}`} />
				</div>
			) : (
				<HorizontalScroll>
					{video && activeTag === "All" && (
						<div className={Styles.cardContainer}>
							<div
								onClick={() => {
									setPhotoIndex(0);
									setIsModalOpen(true);
								}}
								className={Styles.videoContainer}>
								<img src={video.thumbnail} alt="thumbnail" className={Styles.imgCard} />
								<div className={Styles.videoOverlay}>
									<PlayCircleFilled className={Styles.playButton} />
								</div>
							</div>
						</div>
					)}
					{images.map((obj, i) => {
						return (
							<div key={i} className={Styles.cardContainer}>
								<div className={Styles.imgContainer}>
									<img
										alt="projects"
										onClick={() => {
											setPhotoIndex(video ? i + 1 : i);
											setIsModalOpen(true);
										}}
										src={obj.url}
										className={Styles.imgCard}
									/>
								</div>
							</div>
						);
					})}
				</HorizontalScroll>
			)}
			{isModalOpen && (
				<div className={Styles.lightbox}>
					<ReactImageVideoLightbox
						data={gallery}
						startIndex={photoIndex}
						showResourceCount={false}
						onCloseCallback={() => {
							setIsModalOpen(false);
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default Gallery;

const gallery = [
	{
		type: "photo",
		tag: "Robotics Lab",
		url: Drone,
	},
	{
		type: "photo",
		tag: "Coding lab",
		// url: "https://www.sassymamasg.com/wp-content/uploads/2018/10/1-1-1024x796.jpg",
		url: LineFollower
	},
	{
		type: "photo",
		tag: "Workshops",
		// url: "https://jobsgo.vn/blog/wp-content/uploads/2022/08/workshop-la-gi.jpg",
		url: DustBin
	},
	{
		type: "photo",
		tag: "Robotics Lab",
		// url: "https://assets.entrepreneur.com/content/3x2/2000/1659425329-Untitleddesign-2022-08-02T124203665.jpg?auto=webp&quality=95&crop=16:9&width=675",
		url: Class1
	},
	{
		type: "photo",
		tag: "Coding lab",
		// url: "https://www.sassymamasg.com/wp-content/uploads/2018/10/1-1-1024x796.jpg",
		url: Class2
	},
	// {
	// 	type: "photo",
	// 	tag: "Workshops",
	// 	url: "https://jobsgo.vn/blog/wp-content/uploads/2022/08/workshop-la-gi.jpg",
	// },
];
