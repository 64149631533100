import { useState } from "react";
import Carousel from "../../common/Carousel";
import Styles from "./feedbacks.module.css";
import { StarFilled } from "@ant-design/icons";
import ID from "../../../constants/id";

const Feedbacks = () => {
	const [currentSlide, setCurrentSlide] = useState(1);
	const onChange = (_, nextSlide) => {
		setCurrentSlide(nextSlide + 1 >= feedbacks.length ? 0 : nextSlide + 1);
	};

	return (
		<div className={Styles.container} id={ID.TESTIMONIALS}>
			<div className={Styles.heading}>Parents Speak</div>
			<div className={Styles.carouselContainer}>
				<div className={Styles.blur} />
				<Carousel onChange={onChange}>
					{feedbacks.map((feedback, i) => (
						<div key={i} className="d-flex justify-center">
							<div className={`${Styles.card} ${currentSlide === i && Styles.active}`}>
								<div className={Styles.cardHeading}>{feedback.title}</div>
								<div className={Styles.cardText}>{feedback.text}</div>
								{new Array(feedback.stars).fill(0).map((_, i) => (
									<StarFilled key={i} style={{ color: "#FBB040", marginRight: 2 }} />
								))}
								<div className={Styles.userContainer}>
									<img src={feedback.profileImg} alt="profile" className={Styles.profileImg} />
									<div>
										<div className={Styles.user}>
											<span>{feedback.name}</span>
											{
												feedback.role &&
												<span>
												,{" "}{feedback.role}
												</span>
											}
										</div>
										<div className={Styles.institution}>{feedback.institution}</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</Carousel>
				<div className={`${Styles.blur} ${Styles.rightBlur}`} />
			</div>
		</div>
	);
};

export default Feedbacks;

const feedbacks = [
	{
		profileImg: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
		title: "Robotics",
		text: "“My child took the grade 5 robotics class and it was an amazing experience. They learned so much about technology and problem-solving in a fun and engaging way. Their confidence in technology has skyrocketed and I highly recommend this class to other parents.”",
		stars: 5,
		name: "Rohit Kashyap",
		role: "",
		institution: "Bhakt English Medium School - Kakrolia",
	},
	{
		profileImg: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
		title: "Junior Coding",
		text: "“My 6th grader took the coding class and it was a game-changer. They learned to code interactive projects and their creativity flourished. The teacher was knowledgeable and made the class enjoyable. I highly recommend this class to other parents looking to give their child a valuable technology skill.”",
		stars: 5,
		name: "Vikas Raj",
		role: "",
		institution: "Navyug High School - Vadodara",
	},
	{
		profileImg: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
		title: "Senior Coding",
		text: "“My child took the python coding class and I was blown away by the progress they made. They learned the basics of programming and were able to create complex projects by the end of the course. The teacher was excellent, making the subject accessible and fun for the students”",
		stars: 5,
		name: "Arjun Desai",
		role: "",
		institution: "Vivekanand Public School - Palwal",
	},
	{
		profileImg: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
		title: "Robotics & Coding",
		text: "“My child took the robotics and coding class and it was an incredible experience. They learned the fundamentals of coding and applied it to building and programming robots. The hands-on approach to learning kept my child engaged and they developed a love for technology.”",
		stars: 5,
		name: "Ashish Mishra",
		role: "",
		institution: "BVN School - Faridabad",
	},
];
