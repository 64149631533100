import { useState } from "react";
import Carousel from "../../common/Carousel";
import Styles from "./popularcourses.module.css";
import { StarFilled } from "@ant-design/icons";
import ID from "../../../constants/id";
import { Checkbox } from "antd";
// import Checkbox from '@mui/material/Checkbox';

const PopularCourses = () => {
  return (
    <div className={Styles.container} id={ID.TESTIMONIALS}>
      <div className={Styles.heading}>Popular Courses</div>
      <div className={Styles.carouselContainer}>
        {courses.map((courses, i) => (
          <div className={`${Styles.card}`}>
            <div className={`${Styles.cardHeading}`}>{courses.title}</div>
            {courses.data.map((label, i) => {
              return (
                <div style={{ padding: "5px" }}>
                  <Checkbox
                    defaultChecked={true}
                    checked
                    className={`${Styles.coursebox}`}
                  >
                    {label}
                  </Checkbox>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularCourses;

const courses = [
  {
    title: "RoboArmy Basics (Class V onwards)",
    data: [
      "Robotics, Arduino & Coding",
      "Learning by building 45 projects",
      "15 classes",
      "Complementary Robotics Kit",
      "Hackathon & certificate after course completion",
    ],
  },
  {
    title: "RoboArmy Advanced (Class VI onwards)",
    data: [
      "Robotics, Arduino, IOT Coding",
      "Learning by building 55 projects",
      "20 classes",
      "Complementary Robotics Kit",
      "Hackathon & certificate after course completion",
    ],
  },
];
