// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDlJCdSNducRRJhHt0dJsuRONkrKKdo1w0",
  authDomain: "roboarmy-454ea.firebaseapp.com",
  projectId: "roboarmy-454ea",
  storageBucket: "roboarmy-454ea.appspot.com",
  messagingSenderId: "27134456706",
  appId: "1:27134456706:web:574b92be0af822ce60d59b",
  measurementId: "G-SP6F5HKB7X",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const messaging = getMessaging(app);
export { db, analytics, messaging };
