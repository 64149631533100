import Home from "./components/home";
import PrivacyPolicy from "./components/privacy-policy";
import TermsAndCondition from "./components/terms-and-conditions";
import NotFound from "./components/not-found";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Projetcs from "./components/projects";
import ProjetcPage from "./components/projects/projectPage";
import Superadmin from "./superadmin/Superadmin";

const App = () => {
  // const isBreakpoint = useMediaQuery(991);

  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="/my/superadmin" element={<Superadmin />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/project/" element={<Projetcs />} />
          <Route path="/project/:id" element={<ProjetcPage />} />
          {/* <Route path="/logout" element={<Logout />} />
					<Route path="/*" element={<Layout />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
